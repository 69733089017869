<template>
  <!-- send-contact -->
  <div class="modal fade custom-border-modal" id="send-contact">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <span class="material-icons group-add-btn">arrow_forward</span
            >Enviar contato
          </h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="material-icons">close</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="search_chat has-search me-0 ms-0">
            <form @submit="(e) => e.preventDefault()">
              <span class="fas fa-search form-control-feedback"></span>
              <input
                class="form-control chat_input"
                id="search-send-contact"
                type="text"
                v-model="searchQuery"
                @input="inputQuery"
                placeholder="Buscar contatos"
              />
              <button
                type="reset"
                id="reset"
                v-on:click="clearSearch('search-send-contact')"
              >
                &times;
              </button>
            </form>
          </div>
          <div class="sidebar">
            <perfect-scrollbar id="perfect-send-contact" class="scroll-area">
              <div
                class="message-welcome-center"
                v-if="contacts.length == 0 && !isSearching && searchQuery == ''"
              >
                <p>
                  Digite o nome ou telefone do
                  <span class="text-highlight">contato</span> que deseja buscar!
                </p>
              </div>
              <div class="message-welcome-center" v-if="isSearching">
                <p>
                  Buscando
                  <span class="text-highlight">{{ searchQuery }}...</span>
                </p>
              </div>
              <div
                class="message-welcome-center"
                v-if="
                  !isSearching && searchQuery !== '' && contacts?.length == 0
                "
              >
                <p>
                  Nada foi encontrado para
                  <span class="text-highlight">{{ searchQuery }}...</span>
                </p>
              </div>
              <ul
                class="user-list"
                v-for="contact in contacts"
                v-bind:key="contact?.id"
              >
                <li class="user-list-item" v-if="!isSearching">
                  <div class="avatar">
                    <img
                      v-lazy="{
                        src: !contact?.profilePicUrl
                          ? defaultPic
                          : contact?.profilePicUrl,
                        error: defaultPic,
                      }"
                      class="rounded-circle"
                      alt="image"
                    />
                  </div>
                  <div
                    class="users-list-body align-items-center"
                    v-on:click="
                      handlesendContact(
                        contact?.name,
                        contact?.id_api.replace('@s.whatsapp.net', '')
                      )
                    "
                  >
                    <div>
                      <h5>{{ contact?.name }}</h5>
                      <span>{{
                        contact?.id_api?.replace(
                          /([0-9]{2})?([0-9]{2})?([0-9]{4,5})?([0-9]{4})(@s\.whatsapp\.net)/,
                          "($2) $3-$4"
                        )
                      }}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </perfect-scrollbar>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /send-contact -->
</template>

<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import userNoPhotoUrl from "../../assets/img/profile-photo.jpg";
import { config } from "../../public/config";
import { useStore } from "../../stores/dataStore";
import { api } from "../../service/api";
import Swal from "sweetalert2";
import { sendContact } from "../../helpers/message";

export default {
  setup() {
    const data = useStore();

    return { data, defaultPic: userNoPhotoUrl };
  },
  props: ["chat_opened"],
  components: {
    PerfectScrollbar,
  },
  async mounted() {
    this.contacts = this.data.contacts;
    $("#perfect-send-contact").css({ height: "280px" });

    // Busca de contatos para novo chat
    $("#search-send-contact").on("keyup", function () {
      const value = $(this).val().toLowerCase();
      $("#perfect-send-contact ul li").filter(function () {
        $(this).toggle(
          $(this).text().toLowerCase().replace("-", "").indexOf(value) > -1
        );
      });
    });
  },
  data() {
    return {
      baseURL: config.$api_url + "/",
      searchQuery: "",
      contacts: [],
      isSearching: false,
      isTyping: true,
      timeTyping: null,
    };
  },
  methods: {
    async inputQuery() {
      this.isSearching = true;
      clearTimeout(this.timeTyping);
      this.timeTyping = setTimeout(async () => {
        this.findContact();
      }, 500);
    },
    async findContact() {
      if (this.searchQuery == "") {
        this.isSearching = false;
        this.contacts = [];
        return;
      }
      try {
        const { data } = await api.get(`/contacts/find`, {
          params: {
            query: this.searchQuery,
            attributes: "name,id_api,id,profilePicUrl",
            includeGroup: false,
          },
        });
        this.contacts = data;
        this.isSearching = false;
      } catch (error) {
        Swal.fire("Oops!", "Houve um erro ao buscar contatos...");
      }
    },
    clearSearch(id) {
      $("#search-send-contact").val("");
      this.inputQuery();
    },
    handlesendContact(name, number) {
      $(".close").click();
      sendContact(
        this.chat_opened.contact_type,
        this.chat_opened.id_api,
        this.chat_opened.id,
        name,
        number,
        this.reply
      );
      //this.$emit('handlesendContact', 'whatsapp', contact)
      this.clearSearch();
    },
  },
};
</script>
