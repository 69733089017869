<template>
  <!-- Transferir chat -->
  <div class="modal fade custom-border-modal" id="transfer-chat">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <span class="material-icons group-add-btn">arrow_forward</span
            >Transferir chat
          </h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="material-icons">close</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="sidebar">
            <perfect-scrollbar
              id="perfect-forward-transfer"
              class="scroll-area"
            >
              <ul class="user-list" v-if="!(data.users.length > 1)">
                <div class="message-welcome-center">
                  <p>
                    Não há outros
                    <span class="text-highlight">usuários</span> cadastrados.
                  </p>
                </div>
              </ul>
              <ul
                class="user-list"
                v-for="usr in data.users"
                v-bind:key="usr.id"
              >
                <li
                  class="user-list-item"
                  v-on:click="handleTransferCtt(usr.id, usr.name, usr.surname)"
                  v-if="usr.id != data.user.id"
                >
                  <div class="avatar">
                    <img
                      :src="getProfilePic(usr.profilePicUrl)"
                      class="rounded-circle"
                      alt="image"
                    />
                  </div>
                  <div class="users-list-body align-items-center">
                    <div>
                      <h5>{{ usr.name }} {{ usr.surname }}</h5>
                      <!-- <small>{{usr.departments}}</small>-->
                    </div>
                  </div>
                </li>
              </ul>
            </perfect-scrollbar>
          </div>
          <div class="settings-control p-3">
            <ul>
              <li class="d-flex align-items-center">
                <label class="switch me-3">
                  <input
                    type="checkbox"
                    v-model="sendMsgTransfer"
                    id="finishSendMsg"
                    @change="onChangeValue"
                  />
                  <span class="slider round"></span>
                </label>
                <div>
                  <span
                    >Informar ao cliente que o chat está sendo transferido</span
                  >
                </div>
              </li>
            </ul>
            <div class="form-col form-body" v-if="sendMsgTransfer">
              <form>
                <div class="row">
                  <div class="col-12 mt-2">
                    <div class="form-group">
                      <label>Mensagem</label>
                      <textarea
                        class="form-control form-control-lg group_formcontrol"
                        style="height: 80px"
                        spellcheck="false"
                        v-model="msgToTransfer"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Transferir chat -->
</template>

<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import userNoPhotoUrl from "../../assets/img/profile-photo.jpg";
import { config } from "../../public/config";
import { useStore } from "../../stores/dataStore";
import { api } from "../../service/api";
import { sendText } from "../../helpers/message";

export default {
  setup() {
    const data = useStore();

    return { data };
  },
  components: {
    PerfectScrollbar,
  },
  async mounted() {
    this.sendMsgTransfer =
      localStorage.getItem("sendMsgTransfer") === "false" ? false : true;
    $("#perfect-forward-transfer").css({ height: "280px" });
  },
  data() {
    return {
      baseURL: config.$api_url + "/",
      sendMsgTransfer: true,
    };
  },
  methods: {
    handleTransferCtt(userId, userName, surName) {
      $(".close").click();
      this.$emit("closeChat");
      this.data.editContact(this.data.chatIdTransfer.id, {
        userIdOpened: userId,
      });

      api
        .post(
          "/chat/transfer/" + userId + "/" + this.data.chatIdTransfer.id_api
        )
        .then((res) => {
          if (res.data.error) {
            errorSwal(res.data.error);
          }
          if (this.sendMsgTransfer) {
            sendText(
              this.msgToTransfer
                .replace("[nome]", userName)
                ?.replace("[sobrenome]", surName),
              {
                contact_type: this.data.chatIdTransfer.contact_type,
                contact: this.data.chatIdTransfer,
                openChat: false,
              }
            );
          }
        })
        .catch((err) => {
          console.log("Erro ao transferir contato: " + err);
        });
    },
    getProfilePic(photoUrl) {
      if (!photoUrl) {
        return userNoPhotoUrl;
      } else {
        return this.baseURL + photoUrl;
      }
    },
    onChangeValue() {
      localStorage.setItem("sendMsgTransfer", this.sendMsgTransfer);
    },
  },
  computed: {
    msgToTransfer: {
      get() {
        return (
          localStorage.getItem("msgToTransfer") ||
          "> _ⓘ Seu atendimento foi transferido para o atendente [nome] [sobrenome]._"
        );
      },
      set(value) {
        return localStorage.setItem("msgToTransfer", value);
      },
    },
  },
};
</script>
