<script lang="ts">
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import mapbox from "mapbox-gl";

import { sendLocation } from "../../helpers/message";
import { useStore } from "../../stores/dataStore";
import { PropType, defineComponent } from "vue";
import { Contact } from "../../types/Contact";
import { config } from "../../public/config";
import { api } from "../../service/api";

import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "mapbox-gl/dist/mapbox-gl.css";

export default defineComponent({
  props: {
    chat: {
      type: Object as PropType<Contact>,
      required: true,
    },
  },
  setup() {
    return { mapbox, MapboxGeocoder, User: () => useStore(), sendLocation };
  },
  async mounted() {
    this.mapbox.accessToken =
      "pk.eyJ1IjoidGl0YW5jaGF0IiwiYSI6ImNsbTk5c2V2azBla3QzY21ycnAxY2V6MGYifQ.4dNE_Mce08BC3nH9t72W5g";
    const map = new this.mapbox.Map({
      container: "mapa-picker",
      style: "mapbox://styles/titanchat/clm99v1mv02v101nseuh8fibg",
      center: [-43.2118227, -22.9522592], //[lng, lat]
      zoom: 3,
      attributionControl: false,
    });
    const nav = new this.mapbox.NavigationControl();
    map.addControl(nav, "top-left");
    map.addControl(
      new this.mapbox.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
        showUserHeading: true,
      })
    );
    const marker = new this.mapbox.Marker({
      color: "#5A078B",
      draggable: true,
    })
      .setLngLat([-43.2118227, -22.9522592])
      .addTo(map);
    // Add the control to the map.
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const geocoder = new this.MapboxGeocoder({
      accessToken: self.mapbox.accessToken,
      mapboxgl: self.mapbox,
      countries: "br",
      types: "address, poi",
      minLength: 5,
      marker: false,
    });
    map.addControl(geocoder);
    map.on("idle", () => {
      map.resize();
    });
    map.on("load", () => {
      map.resize();
    });

    map.on("click", function add_marker(event: any) {
      const coordinates: any = event.lngLat;
      marker.setLngLat(coordinates).addTo(map);
      while (Math.abs(event.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += event.lngLat.lng > coordinates[0] ? 360 : -360;
      }
      self.lng = event.lngLat.lng;
      self.lat = event.lngLat.lat;
      self.loc = "";
    });
    geocoder.on("result", function (r: any) {
      marker.remove();
      self.lng = r.result.center[0];
      self.lat = r.result.center[1];
      self.loc = r.result["place_name_pt-BR"];
      marker.setLngLat(r.result.center).addTo(map);
    });
  },
  data() {
    return {
      baseURL: config.$api_url + "/",
      lng: -43.2118227,
      lat: -22.9522592,
      loc: "Centro, Rio de Janeiro - RJ",
    };
  },
  methods: {
    handleSendLocation(lng: number, lat: number, loc: string) {
      sendLocation(
        this.chat.contact_type,
        this.chat.id_api,
        this.chat.id,
        lng,
        lat,
        loc
      );
    },
    addLocation() {
      if (!this.User().config.locations) this.User().config.locations = [];
      (this.User().config.locations as any).push({
        lat: this.lat,
        lng: this.lng,
        loc: this.loc,
      });
      this.saveLocation();
    },
    saveLocation() {
      api.put("/master/locations", {
        locations: this.User().config.locations,
      });
    },
    delLocation(location: any) {
      this.User().config.locations = this.User().config?.locations?.filter(
        (loc) => loc !== location
      );
      this.saveLocation();
    },
  },
});
</script>
<template>
  <!-- send-location -->
  <div class="modal fade custom-border-modal" id="send-location">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <span class="material-icons group-add-btn">map</span>Enviar
            localização
          </h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="material-icons">close</span>
          </button>
        </div>
        <div class="modal-body">
          <section class="map_box_container">
            <!--MAP-->
            <div id="mapa-picker" width="800px" height="360px"></div>
          </section>
          <div class="row mt-3">
            <div class="col-5">
              <div class="form-group">
                <label>Mensagem da localização</label>
                <input
                  class="form-control form-control-lg group_formcontrol"
                  v-model="loc"
                  name="loc"
                  type="text"
                />
              </div>
            </div>
            <div class="col-2">
              <div class="form-group">
                <label>Longitude</label>
                <input
                  class="form-control form-control-lg group_formcontrol"
                  v-model="lng"
                  name="loc"
                  type="number"
                />
              </div>
            </div>
            <div class="col-2">
              <div class="form-group">
                <label>Latitude</label>
                <input
                  class="form-control form-control-lg group_formcontrol"
                  v-model="lat"
                  name="loc"
                  type="number"
                />
              </div>
            </div>
            <div class="col-1 mt-4">
              <button
                type="button"
                class="btn newgroup_create"
                v-on:click="addLocation"
              >
                <span>Salvar</span>
              </button>
            </div>
            <div class="col-1 mt-4">
              <button
                type="button"
                class="btn newgroup_create"
                @click="handleSendLocation(lng, lat, loc)"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span>Enviar</span>
              </button>
            </div>
          </div>

          <div
            class="row mt-1"
            v-for="location of User().config.locations"
            :key="location.lat"
          >
            <div class="col-5">
              <div class="form-group">
                <input
                  class="form-control form-control-lg group_formcontrol"
                  v-model="location.loc"
                  name="loc"
                  @blur="saveLocation"
                  type="text"
                />
              </div>
            </div>
            <div class="col-2">
              <div class="form-group">
                <input
                  class="form-control form-control-lg group_formcontrol"
                  v-model="location.lng"
                  @blur="saveLocation"
                  name="loc"
                  type="number"
                />
              </div>
            </div>
            <div class="col-2">
              <div class="form-group">
                <input
                  class="form-control form-control-lg group_formcontrol"
                  v-model="location.lat"
                  @blur="saveLocation"
                  name="loc"
                  type="number"
                />
              </div>
            </div>
            <div class="col-1 mt-3">
              <i
                class="fas fa-trash"
                style="cursor: pointer; color: rgb(204, 82, 82)"
                v-on:click="delLocation(location)"
              ></i>
            </div>
            <div class="col-1 p-0">
              <button
                type="button"
                class="btn newgroup_create"
                @click="
                  handleSendLocation(location.lng, location.lat, location.loc)
                "
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span>Enviar</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /send-location -->
</template>
<style>
#mapa-picker {
  width: 100%;
  height: 360px;
}
</style>
