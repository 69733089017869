<template>
  <!-- Modal - Enviar item -->
  <div class="modal fade custom-border-modal" id="send-item">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <span class="material-icons group-add-btn">link</span>Enviar
          </h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="material-icons">close</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="sidebar2">
            <ul class="user-list2">
              <li
                class="user-list-item2"
                v-if="chat_opened?.contact_type !== 'messenger'"
                data-bs-toggle="modal"
                data-bs-target="#send-contact"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <div class="avatar">
                  <img
                    src="https://cdn1.iconfinder.com/data/icons/basic-22/512/1041_girl_c-512.png"
                    class="rounded-circle"
                    alt="image"
                  />
                </div>
                <div class="users-list-body align-items-center">
                  <div>
                    <h5>Enviar contato</h5>
                    <small>Envie um contato para seu cliente</small>
                  </div>
                </div>
              </li>
              <li
                class="user-list-item2"
                v-if="chat_opened?.contact_type !== 'messenger'"
                data-bs-toggle="modal"
                data-bs-target="#send-location"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <div class="avatar">
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWu1LpLNWZQzzOCZa3CfY_RZm4GZL69QUQnQ&usqp=CAU"
                    class="rounded-circle"
                    alt="image"
                  />
                </div>
                <div class="users-list-body align-items-center">
                  <div>
                    <h5>Enviar localização</h5>
                    <small>Envie uma localização de um mapa</small>
                  </div>
                </div>
              </li>
              <li class="user-list-item2">
                <div class="avatar">
                  <img
                    src="https://cdn.picpng.com/icon/upload-files-icon-66764.png"
                    class="rounded-circle"
                    alt="image"
                  />
                </div>
                <div
                  class="users-list-body align-items-center"
                  @click="handleClickButtonUpload"
                >
                  <div>
                    <h5>Enviar arquivo</h5>
                    <small>Anexe fotos, áudio, video, documento</small>
                  </div>
                </div>
              </li>
              <li
                v-if="chat_opened?.contact_type == 'whatsapp'"
                class="user-list-item2"
                data-bs-toggle="modal"
                data-bs-target="#send-poll"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <div class="avatar">
                  <img
                    src="https://brasilsoftbol.files.wordpress.com/2017/02/icone_materias.png"
                    class="rounded-circle"
                    alt="image"
                  />
                </div>
                <div class="users-list-body align-items-center">
                  <div>
                    <h5>Enviar enquete</h5>
                    <small>Envie uma enquete para colher dados</small>
                  </div>
                </div>
              </li>
              <li
                class="user-list-item2"
                style="pointer-events: none; opacity: 0.5"
                data-bs-toggle="modal"
                data-bs-target="#send-poll"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <div class="avatar">
                  <img
                    src="https://st3.depositphotos.com/9081830/13830/v/600/depositphotos_138301660-stock-illustration-chatbot-or-chatterbot-icon-concept.jpg"
                    class="rounded-circle"
                    alt="image"
                  />
                </div>
                <div class="users-list-body align-items-center">
                  <div>
                    <h5>Enviar BOT</h5>
                    <small
                      >Envie um BOT já configurado, para atendimento
                      automático</small
                    >
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Modal - Enviar item -->
</template>

<script>
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";

export default {
  props: ["chat_opened"],
  async mounted() {
    $("#perfect-senditems").css({ height: "400px" });
  },
  data() {
    return {};
  },
  methods: {
    handleClickButtonUpload() {
      $(".close").click();
      document.getElementById("chatUpload").click();
    },
  },
};
</script>
