// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.new-contact-btn {
  width: 100%;
  height: 45px;
  border-radius: 3px;
  border: 1px solid #d3dbee;
  background-color: #fff;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  box-shadow: 0px 4px 4px #f5f8ff;
  font-size: 14px;
  color: #420ba1;
}
.darkmode .new-contact-btn {
  border: 1px solid #3e4a56;
  background-color: #3e4a56;
  box-shadow: none;
  list-style: none;
  color: #e5eeff;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
