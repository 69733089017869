<template>
                   <!-- Add User -->
        <div class="modal fade" id="reaction-message">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <span class="material-icons">favorite</span>Reagir a mensagem
                        </h5>
                        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                            <span class="material-icons">close</span>
                        </button>
                    </div>
                    <div class="modal-body">
                            <div class="form-group react-body" >
                                <div class="buttom-react-msg" v-on:click="sendReaction('❤️')">❤️</div>
                                <div class="buttom-react-msg" v-on:click="sendReaction('😂')">😂</div> 
                                <div class="buttom-react-msg" v-on:click="sendReaction('😯')">😯 </div> 
                                <div class="buttom-react-msg" v-on:click="sendReaction('😢')">😢</div> 
                                <div class="buttom-react-msg" v-on:click="sendReaction('👍')">👍</div> 
                                <div class="buttom-react-msg" v-on:click="sendReaction('🙏')">🙏</div> 
                                <div class="buttom-react-msg" v-on:click="sendReaction('🚀')">🚀</div> 
                                <div class="buttom-react-msg" v-on:click="sendReaction('🏃')">🏃</div> 
                                <div class="buttom-react-msg" v-on:click="sendReaction('😋')">😋</div> 
                                <div class="buttom-react-msg" v-on:click="sendReaction('💜')">💜</div> 
                                <div class="buttom-react-msg" v-on:click="sendReaction('👀')">👀</div> 
                                <div class="buttom-react-msg" v-on:click="sendReaction('😡')">😡</div> 
                            </div>
                    </div>       
                </div>
            </div>
        </div>
        <!-- /Add User -->
</template>
<script> 

export default {
    methods:{
        sendReaction(string) {
            $('.close').click()
            this.$emit('sendReaction',string)
        }
    }
}

</script>