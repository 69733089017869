<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import userNoPhotoUrl from "../../assets/img/profile-photo.jpg";
import { config } from "../../public/config";
import { useStore } from "../../stores/dataStore";
import { ChatCollection } from "../../stores/chatsStore";
import { api } from "../../service/api";
import { db } from "../../dbs/db";
import Swal from "sweetalert2";

export default {
  setup() {
    const data = useStore();

    return { data, db, ChatCollection };
  },
  components: {
    PerfectScrollbar,
  },
  async mounted() {
    $("#perfect-new-chat").css({ height: "280px" });
    let contacts = await this.db.contacts.toArray();
    if (contacts.length > 0) {
      contacts.sort((a, b) => a?.name?.localeCompare(b?.name));
      contacts = contacts.filter(
        (contact) =>
          !contact.id_api?.includes("@g.us") &&
          !this.ChatCollection().get(contact.id)
      );
      this.contacts = contacts?.slice(0, 40);
      this.startContacts = contacts?.slice(0, 40);
    } else {
      this.contacts = [];
      this.startContacts = [];
    }
  },
  data() {
    return {
      baseURL: config.$api_url + "/",
      searchQuery: "",
      isSearching: false,
      isTyping: true,
      timeTyping: null,
      contacts: [],
      startContacts: [],
    };
  },
  methods: {
    clearSearch() {
      $("#search-new-chat").val("");
      this.contacts = this.startContacts;
    },
    async inputQuery() {
      this.isSearching = true;
      clearTimeout(this.timeTyping);
      this.timeTyping = setTimeout(async () => {
        this.findContact();
      }, 200);
    },
    async findContact() {
      if (this.searchQuery == "") {
        this.isSearching = false;
        this.contacts = this.startContacts;
        return;
      } else {
        try {
          const normalizeAndLowercase = (texto) => {
            if (!texto || texto == "") return "";
            return texto
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase();
          };
          const all = await this.db.contacts.toArray();
          const filtered = all.filter((contact) => {
            const searchWords = normalizeAndLowercase(this.searchQuery).split(
              " "
            );
            return (
              !contact.id_api?.includes("@g.us") &&
              searchWords.every((word) => {
                return (
                  normalizeAndLowercase(contact.name)?.includes(
                    normalizeAndLowercase(word)
                  ) ||
                  normalizeAndLowercase(contact.pushname)?.includes(
                    normalizeAndLowercase(word)
                  ) ||
                  normalizeAndLowercase(contact.id_api)?.includes(
                    normalizeAndLowercase(word)
                  )
                );
              })
            );
          });
          filtered.sort((a, b) => a?.name?.localeCompare(b?.name));
          this.contacts = filtered;
          this.isSearching = false;
        } catch (error) {
          throw new Error(error);
        }
      }
    },
    async newChatWithContact(chat) {
      this.data.assignChatToMe(chat);
      $("#search-new-chat").val("");
      $(".close").click();
    },
    async newChatWithUser(user) {
      $("#search-new-chat").val("");
      $(".close").click();
    },
    getProfilePic(photoUrl) {
      if (!photoUrl) {
        return userNoPhotoUrl;
      } else {
        return photoUrl;
      }
    },
    errorOnProfilePic() {
      return userNoPhotoUrl;
    },
  },
};
</script>

<template>
  <!-- New chat with contact -->
  <div class="modal fade custom-border-modal" id="new-chat-with-contact">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <span class="material-icons group-add-btn">arrow_forward</span>Nova
            conversa
          </h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="clearSearch"
          >
            <span class="material-icons">close</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="search_chat has-search me-0 ms-0">
            <form @submit="(e) => e.preventDefault()">
              <span class="fas fa-search form-control-feedback"></span>
              <input
                class="form-control chat_input"
                id="search-new-chat"
                v-model="searchQuery"
                @input="inputQuery"
                type="text"
                placeholder="Buscar contatos"
              />
              <button type="reset" id="reset" v-on:click="clearSearch()">
                &times;
              </button>
            </form>
          </div>
          <div class="sidebar">
            <perfect-scrollbar id="perfect-new-chat" class="scroll-area">
              <!--
              <div v-if="contacts > 0">
                <h6>Usuários</h6>
                <ul
                  class="user-list"
                  v-for="user in data.users"
                  v-bind:key="user.id"
                >
                  <li class="user-list-item">
                    <div class="avatar">
                      <img
                        v-lazy="{
                          src: getProfilePic(user.profilePicUrl),
                          error: errorOnProfilePic(),
                        }"
                        class="rounded-circle"
                        alt="image"
                      />
                    </div>
                    <div
                      class="users-list-body align-items-center"
                      v-on:click="newChatWithUser(user)"
                    >
                      <div>
                        <h5>{{ user.name }} {{ user.surname }}</h5>
                        <span>{{ user.email }}</span>
                      </div>
                    </div>
                  </li>
                </ul>
                <hr />
              </div>
              -->

              <li style="list-style: none" class="col-12">
                <a
                  href="javascript:void(0);"
                  class="new-contact-btn"
                  id="add-contact-buttom"
                  data-bs-toggle="modal"
                  data-bs-target="#add-user"
                >
                  <i
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="Novo contato"
                    class="fas fa-plus"
                  ></i>
                  Novo contato
                </a>
              </li>
              <div
                class="message-welcome-center"
                v-if="contacts.length == 0 && !isSearching"
              >
                <p>
                  Digite o nome ou telefone do
                  <span class="text-highlight">contato</span> que deseja buscar!
                </p>
              </div>
              <div class="message-welcome-center" v-if="isSearching">
                <p>
                  <i
                    class="fas fa-spinner fa-spin text-highlight"
                    style="font-size: 40pt"
                  ></i>
                </p>
              </div>
              <div
                class="message-welcome-center"
                v-if="
                  !isSearching && searchQuery !== '' && contacts.length == 0
                "
              >
                <p>
                  Nada foi encontrado para
                  <span class="text-highlight">{{ searchQuery }}...</span>
                </p>
              </div>
              <ul
                class="user-list"
                v-for="contact in contacts"
                v-bind:key="contact?.id_api"
              >
                <li class="user-list-item" v-if="!isSearching">
                  <div
                    class="avatar"
                    :class="{
                      'avatar-online': contact.isOnline == true,
                      'avatar-telegram':
                        contact.contact_type === 'telegram_bot',
                      'avatar-instagram': contact.contact_type === 'instagram',
                      'avatar-facebook': contact.contact_type === 'facebook',
                      'avatar-twitter': contact.contact_type === 'twitter',
                    }"
                  >
                    <img
                      v-lazy="{
                        src: getProfilePic(contact.profilePicUrl),
                        error: errorOnProfilePic(),
                      }"
                      class="rounded-circle"
                      alt="image"
                    />
                  </div>
                  <div
                    class="users-list-body align-items-center"
                    v-on:click="newChatWithContact(contact)"
                  >
                    <div>
                      <h5>{{ contact.name }}</h5>
                      <template v-if="contact.pushname">
                        <span><b>Interno:</b> {{ contact.pushname }}</span>
                      </template>
                      <span>{{
                        contact.id_api.replace(
                          /([0-9]{2})?([0-9]{2})?([0-9]{4,5})?([0-9]{4})(@s\.whatsapp\.net)/,
                          "($2) $3-$4"
                        )
                      }}</span>
                    </div>
                  </div>

                  <div
                    class="transfer-badge"
                    v-if="
                      contact.userIdOpened &&
                      contact.userIdOpened !== data.user.id
                    "
                  >
                    <span class="text-transfer"
                      >Em chat com
                      {{ data.getUserById(contact.userIdOpened)?.name }}</span
                    >
                  </div>
                </li>
              </ul>
            </perfect-scrollbar>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /New chat with contact -->
</template>
<style>
.new-contact-btn {
  width: 100%;
  height: 45px;
  border-radius: 3px;
  border: 1px solid #d3dbee;
  background-color: #fff;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  box-shadow: 0px 4px 4px #f5f8ff;
  font-size: 14px;
  color: #420ba1;
}
.darkmode .new-contact-btn {
  border: 1px solid #3e4a56;
  background-color: #3e4a56;
  box-shadow: none;
  list-style: none;
  color: #e5eeff;
}
</style>
