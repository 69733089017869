// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.card-stat-chat {
  background-color: #fafbff;
}
.darkmode .card-stat-chat {
  border: #747e8a 1px solid;
  border-radius: 5px;
}
.darkmode .card-stat-chat .card-body {
  background-color: #36404a;
  border-radius: 5px;
}
.dash-widget-icon {
  align-items: center;
  border-radius: 50%;
  color: #fff;
  display: inline-flex;
  font-size: 1.875rem;
  height: 50px;
  justify-content: center;
  line-height: 48px;
  text-align: center;
  width: 50px;
  background-color: #747e8a;
}
.dash-widget-icon .fe-phone {
  font-size: 1.5rem;
}
.dash-count {
  font-size: 18px;
  margin-left: auto;
}
.count-title {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  color: #76838f;
  text-align: right;
}
.dash-count a {
  clear: both;
  display: block;
}
.dash-count .count {
  font-size: 32px;
  color: #76838f;
  font-weight: 600;
  text-align: right;
}
.card.active .dash-count .count {
  color: #fff;
}
.dash-widget-info h3 {
  margin-bottom: 10px;
}
.dash-widget-header {
  align-items: center;
  display: flex;
}
.card-chart .card-body {
  padding: 8px;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
