<template>
  <div
    class="right-sidebar right_sidebar_profile"
    id="middle1"
    v-if="chat_opened"
  >
    <div class="right-sidebar-wrap active">
      <div v-if="chat_opened">
        <perfect-scrollbar class="scroll-area">
          <div
            class="left-chat-title d-flex justify-content-between align-items-center p-3"
          >
            <div class="chat-title">
              <h4>
                PERFIL
                <span v-if="chat_opened.contact_type === 'telegram_bot'"
                  >- TELEGRAM</span
                >
                <span v-if="chat_opened.contact_type === 'whatsapp'"
                  >- WHATSAPP</span
                >
              </h4>
            </div>
            <div class="contact-close_call text-end">
              <a
                href="javascript:void(0);"
                class="close_profile close_profile4"
              >
                <span class="material-icons" @click="showInfoChat">close</span>
              </a>
            </div>
          </div>
          <div class="sidebar-body">
            <div class="mt-0 right_sidebar_logo">
              <div class="text-center mb-2 right-sidebar-profile">
                <figure class="avatar avatar-xl mb-3">
                  <a
                    :href="
                      !chat_opened.profilePicUrl
                        ? defaultPic
                        : chat_opened.profilePicUrl
                    "
                    data-fancybox="gallery"
                    class="fancybox"
                  >
                    <img
                      v-lazy="{
                        src: !chat_opened.profilePicUrl
                          ? defaultPic
                          : chat_opened.profilePicUrl,
                        error: defaultPic,
                      }"
                      alt=""
                      class="rounded-circle"
                    />
                  </a>
                </figure>
                <h5
                  class="profile-name"
                  id="nameEditableSidebar"
                  spellcheck="false"
                  contenteditable="true"
                  @keydown.tab="updateNameSidebar"
                  @blur="updateNameSidebar"
                >
                  {{ chat_opened.name }}
                </h5>
                <div class="create-group-date" v-if="chatIsGroup">
                  Criado em {{ createdDate }}
                </div>
                <div class="edit-group" v-if="data.labels">
                  <a
                    href="javascript:void(0);"
                    data-bs-toggle="modal"
                    data-bs-target="#edit-label"
                  >
                    Editar etiqueta <span><i class="fas fa-tag"></i></span
                  ></a>
                </div>
                <div
                  class="edit-group"
                  v-if="chatIsGroup && chat_opened.isGroupAdmin"
                >
                  <a
                    href="javascript:void(0);"
                    data-bs-toggle="modal"
                    data-bs-target="#edit-new-group"
                    ><span><i class="fas fa-edit"></i></span> Editar grupo</a
                  >
                </div>
                <div
                  class="online-profile"
                  v-if="chat_opened.isOnline && !chatIsGroup"
                >
                  <span>online</span>
                </div>
              </div>
              <div>
                <div class="about-media-tabs">
                  <nav>
                    <div
                      class="nav nav-tabs justify-content-center"
                      id="nav-tab"
                    >
                      <a
                        class="nav-item nav-link active"
                        id="nav-home-tab"
                        data-bs-toggle="tab"
                        href="#about"
                        >Info</a
                      >
                      <a
                        class="nav-item nav-link"
                        v-if="chatIsGroup"
                        id="nav-participants-tab"
                        data-bs-toggle="tab"
                        href="#Participants"
                        >Membros ({{ groupQntParticipants }})</a
                      >
                      <a
                        class="nav-item nav-link"
                        id="nav-profile-tab"
                        data-bs-toggle="tab"
                        @click="loadMoreMedia"
                        href="#media"
                        >Mídia & Docs</a
                      >
                    </div>
                  </nav>
                  <div class="tab-content" id="nav-tabContent">
                    <div class="tab-pane fade show active" id="about">
                      <p v-if="chatIsGroup" v-html="descGroup"></p>
                      <div class="member-details">
                        <ul>
                          <li
                            v-if="
                              !chatIsGroup &&
                              chat_opened.contact_type === 'whatsapp'
                            "
                          >
                            <h6>WhatsApp</h6>
                            <span>{{
                              chat_opened.id_api.replace(
                                /([0-9]{2})?([0-9]{2})?([0-9]{4,5})?([0-9]{4})(@s\.whatsapp\.net)/,
                                "($2) $3-$4"
                              )
                            }}</span>
                          </li>
                          <li
                            v-if="
                              chat_opened.pushname &&
                              !chatIsGroup &&
                              chat_opened.contact_type === 'whatsapp'
                            "
                          >
                            <h6>Nome no WhatsApp</h6>
                            <span>{{ chat_opened.pushname }}</span>
                          </li>
                          <li
                            v-if="
                              chat_opened.pushname &&
                              !chatIsGroup &&
                              chat_opened.contact_type === 'telegram_bot'
                            "
                          >
                            <h6>Usuário</h6>
                            <span>@{{ chat_opened.pushname }}</span>
                          </li>
                          <li
                            v-for="question in data.config.customdata"
                            v-bind:key="question.key"
                          >
                            <template v-if="question.showchat == true">
                              <h6>{{ question.name }}</h6>
                              <span
                                :id="question.name"
                                @keydown.tab.prevent=""
                                @keydown.enter.prevent=""
                                v-on:click="cleanHTMLDIV"
                                spellcheck="false"
                                v-on:blur="updateCustomDatas"
                                contenteditable="true"
                                v-html="
                                  getTextToShowCustomDataDivEditable(
                                    getContactCustomDatas(question.name)
                                  )
                                "
                              ></span>
                            </template>
                          </li>
                          <li v-if="chat_opened.mail">
                            <h6>Email</h6>
                            <span>{{ chat_opened.mail }}</span>
                          </li>
                          <li>
                            <h6>Anotações internas</h6>
                            <span
                              id="contentAnottations"
                              v-on:click="cleanDivEditable('anottations')"
                              contenteditable="true"
                              v-on:blur="updateAnottations"
                              spellcheck="false"
                              v-html="getTextToShowDivEditable('anottations')"
                            ></span>
                          </li>
                          <li>
                            <h6>Empresa</h6>
                            <CreateOrSearchCompany
                              :companyId="chat_opened.companyId"
                              :contactId="chat_opened.id"
                            />
                          </li>
                        </ul>
                      </div>
                      <div class="settings-col" v-if="chatIsGroup">
                        <h6>Ajustes</h6>
                        <ul>
                          <li class="d-flex align-items-center">
                            <label class="switch">
                              <input type="checkbox" checked />
                              <span class="slider round"></span>
                            </label>
                            <div>
                              <span>Somente admin enviar mensagem</span>
                            </div>
                          </li>
                          <li class="d-flex align-items-center">
                            <label class="switch">
                              <input type="checkbox" />
                              <span class="slider round"></span>
                            </label>
                            <div>
                              <span>Notificações</span>
                            </div>
                          </li>
                        </ul>
                      </div>

                      <div class="settings-col">
                        <h6>Configurações</h6>
                        <ul>
                          <li class="d-flex align-items-center">
                            <label class="switch">
                              <input type="checkbox" v-model="ignoreBot" />
                              <span class="slider round"></span>
                            </label>
                            <div>
                              <span>Ignorar no Bot</span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="Participants"
                      v-if="chatIsGroup"
                    >
                      <div class="participants-list">
                        <ul class="user-list mt-2">
                          <li
                            class="d-flex"
                            v-for="(member, index) in groupParticipants"
                            v-bind:key="index"
                          >
                            <div>
                              <div
                                class="avatar"
                                v-bind:class="{
                                  'avatar-online': getIsOnlineParticipantGroup(
                                    member.id,
                                    member
                                  ),
                                }"
                              >
                                <img
                                  :src="
                                    getPhotoParticipantGroup(member.id, member)
                                  "
                                  @error="replaceByDefault"
                                  class="rounded-circle"
                                  alt="image"
                                />
                              </div>
                            </div>
                            <div
                              class="users-list-body d-flex justify-content-between"
                            >
                              <div>
                                <h5
                                  v-html="
                                    getNameParticipantGroup(
                                      member.id,
                                      member.id,
                                      member
                                    )
                                  "
                                ></h5>
                              </div>
                              <div v-if="member.admin">
                                <span class="admin-profiles">admin</span>
                              </div>
                              <div v-else-if="member.isSuperAdmin">
                                <span class="admin-profiles">master</span>
                              </div>
                              <div
                                class="grp-delete"
                                v-if="!member.admin && !member.isSuperAdmin"
                              >
                                <div class="chat-action-col">
                                  <a
                                    class="#"
                                    href="#"
                                    data-bs-toggle="dropdown"
                                  >
                                    <i class="fas fa-ellipsis-h"></i>
                                  </a>
                                  <div class="dropdown-menu dropdown-menu-end">
                                    <a
                                      href="javascript:void(0);"
                                      class="dropdown-item"
                                      >Remover
                                      <span
                                        ><i class="far fa-trash-alt"></i></span
                                    ></a>
                                    <a
                                      href="javascript:void(0);"
                                      class="dropdown-item"
                                      >Tornar admin
                                      <span
                                        ><i class="far fa-trash-alt"></i></span
                                    ></a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                        <div class="text-center">
                          <a
                            href="javascript:void(0);"
                            v-if="groupQntParticipants - 10 > 0"
                            class="load-more-btn"
                            >Mais {{ groupQntParticipants - 10 }}
                            <i class="fas fa-sort-down"></i
                          ></a>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="media">
                      <div
                        v-if="isLoading === true"
                        class="d-flex justify-content-center"
                      >
                        <i
                          class="fas fa-spinner fa-spin"
                          style="font-size: 40pt"
                        ></i>
                      </div>
                      <div class="file-download-col">
                        <ul>
                          <li v-for="md in medias" v-bind:key="md.id">
                            <template v-if="md.type === 'image'">
                              <div class="image-download-col">
                                <a
                                  :href="
                                    'https://titanchat-uploads.s3.sa-east-1.amazonaws.com/' +
                                    md.path
                                  "
                                  data-fancybox="gallery"
                                  class="fancybox"
                                >
                                  <img
                                    :src="getThumb(md.path)"
                                    style="
                                      width: 120px;
                                      height: 120px;
                                      object-fit: cover;
                                      background-color: #f0f0f0;
                                    "
                                    alt=""
                                  />
                                </a>
                                <div
                                  class="download-action d-flex align-items-center"
                                >
                                  <div>
                                    <a href="javascript:void(0);"
                                      ><i class="fas fa-cloud-download-alt"></i
                                    ></a>
                                  </div>
                                  <div>
                                    <a href="javascript:void(0);"
                                      ><i class="fas fa-ellipsis-h"></i
                                    ></a>
                                  </div>
                                </div>
                              </div>
                            </template>
                            <template v-if="md.type === 'document'">
                              <div class="image-download-col">
                                <a
                                  :href="
                                    'https://titanchat-uploads.s3.sa-east-1.amazonaws.com/' +
                                    md.path
                                  "
                                  target="_blank"
                                >
                                  <div
                                    style="
                                      width: 120px;
                                      height: 120px;
                                      background-color: #485563;
                                      position: relative;
                                    "
                                  ></div>
                                  <div
                                    class="first-txt"
                                    style="
                                      max-width: 110px;
                                      max-height: 110px;
                                      position: absolute;
                                      bottom: 50px;
                                      left: 3px;
                                      text-align: center;
                                      font-size: 8pt;
                                      word-break: break-all;
                                    "
                                  >
                                    <span style="color: #bed4f1">{{
                                      md.filename
                                    }}</span>
                                  </div>
                                </a>
                                <div
                                  class="download-action d-flex align-items-center"
                                >
                                  <div>
                                    <a
                                      :href="
                                        'https://titanchat-uploads.s3.sa-east-1.amazonaws.com/' +
                                        md.path
                                      "
                                      ><i class="fas fa-cloud-download-alt"></i
                                    ></a>
                                  </div>
                                  <div>
                                    <a href="javascript:void(0);"
                                      ><i class="fas fa-ellipsis-h"></i
                                    ></a>
                                  </div>
                                </div>
                              </div>
                            </template>
                            <template v-if="md.type === 'video'">
                              <div class="image-download-col">
                                <video
                                  controls=""
                                  style="
                                    width: 120px;
                                    height: 120px;
                                    object-fit: cover;
                                    background-color: #f0f0f0;
                                    position: relative;
                                    content-align: center;
                                  "
                                >
                                  <source
                                    :src="
                                      'https://titanchat-uploads.s3.sa-east-1.amazonaws.com/' +
                                      md.path
                                    "
                                  />
                                </video>
                                <div
                                  class="download-action d-flex align-items-center"
                                >
                                  <div>
                                    <a
                                      :href="
                                        'https://titanchat-uploads.s3.sa-east-1.amazonaws.com/' +
                                        md.path
                                      "
                                      ><i class="fas fa-cloud-download-alt"></i
                                    ></a>
                                  </div>
                                  <div>
                                    <a href="javascript:void(0);"
                                      ><i class="fas fa-ellipsis-h"></i
                                    ></a>
                                  </div>
                                </div>
                              </div>
                            </template>
                          </li>
                          <li class="full-width text-center">
                            <a
                              href="javascript:void(0);"
                              v-on:click="loadMoreMedia"
                              class="load-more-btn"
                              >Carregar +20 arquivos
                              <i class="fas fa-sort-down"></i
                            ></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="report-col" v-if="chatIsGroup">
            <ul>
              <li>
                <a href="javascript:void(0);"
                  ><span><i class="fas fa-sign-out-alt"></i></span> Sair do
                  grupo</a
                >
              </li>
              <li>
                <a href="javascript:void(0);"
                  ><span><i class="far fa-trash-alt"></i></span> Deletar
                  grupo</a
                >
              </li>
            </ul>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
</template>
<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";

import { useStore } from "../../stores/dataStore";
import { config } from "../../public/config";
import { api } from "../../service/api";
import moment from "moment";
import userNoPhotoUrl from "../../assets/img/profile-photo.jpg";
import CreateOrSearchCompany from "../Companies/widgets/CreateOrSearchCompany.vue";
import { db } from "../../dbs/db";

export default {
  props: ["chat_opened"],
  components: {
    PerfectScrollbar,
    CreateOrSearchCompany,
  },
  setup() {
    const data = useStore();

    return { data, defaultPic: userNoPhotoUrl, db };
  },
  data() {
    return {
      $api: this.$api,
      apiURL: config.$api_url + "/",
      isLoading: false,
      medias: [],
    };
  },
  methods: {
    getProfilePic(photoUrl) {
      if (!photoUrl) {
        return userNoPhotoUrl;
      } else {
        return this.apiURL + photoUrl;
      }
    },
    replaceByDefault(e) {
      e.target.src = userNoPhotoUrl;
    },

    /* Funções que executam edições no contato à partir da página inicial */
    updateAnottations() {
      const anottations = $("#contentAnottations").text();
      if (!(anottations.length > 0)) {
        $("#contentAnottations").html("<i>Clique e edite</i>");
      }
      this.chat_opened.anottations = anottations;
      api.post("/contacts/upgrade/anottations", {
        contact_type: "whatsapp",
        idApi: this.chat_opened.id_api,
        anottations: anottations,
      });
    },
    updateNameSidebar() {
      const name = $("#nameEditableSidebar").text();
      this.chat_opened.name = name;

      api.post("/contacts/upgrade/name", {
        contact_type: "whatsapp",
        idApi: this.chat_opened.id_api,
        name: name,
      });
    },

    updateCustomDatas(e) {
      const title = e.target.id;
      const text = e.target.innerText;
      if (!(text.length > 0)) {
        $(e.target).html("<i>Clique e edite</i>");
      }

      const array = {
        name: title,
        value: text,
      };
      if (!this.chat_opened.customdatas) {
        this.chat_opened.customdatas = JSON.stringify([array]);
        api.post("/contacts/upgrade/customdatas", {
          idApi: this.chat_opened.id_api,
          customdatas: this.chat_opened.customdatas,
        });
      } else {
        const customdatas = JSON.parse(this.chat_opened.customdatas);
        // Verificando se o dado já existe no cliente e alterando
        let customdatasAtualizada = "";
        customdatas.map((cdt) => {
          if (cdt.name == title) {
            cdt.value = text;
            customdatasAtualizada = JSON.stringify(customdatas);
          }
        });
        if (!customdatasAtualizada) {
          customdatas.push(array);
          customdatasAtualizada = JSON.stringify(customdatas);
        }
        this.chat_opened.customdatas = customdatasAtualizada;
        api.post("/contacts/upgrade/customdatas", {
          idApi: this.chat_opened.id_api,
          customdatas: customdatasAtualizada,
        });
      }
    },
    getTextToShowDivEditable(type) {
      if (type == "anottations") {
        if (this.chat_opened.anottations) {
          return this.chat_opened.anottations;
        } else {
          return "<i>Clique e edite</i>";
        }
      }
    },

    getTextToShowCustomDataDivEditable(text) {
      if (text) {
        return text;
      } else {
        return "<i>Clique e edite</i>";
      }
    },
    cleanDivEditable(type) {
      if (type == "anottations") {
        if (
          this.chat_opened.anottations == "" ||
          !this.chat_opened.anottations ||
          this.chat_opened.anottations == null
        ) {
          $("#contentAnottations").html("");
        }
      }
    },
    cleanHTMLDIV(e) {
      if (e.target.innerText == "Clique e edite") {
        $(e.target).html("");
      }
    },

    getContactCustomDatas(dataName) {
      if (!this.chat_opened) return true;
      if (!this.chat_opened.customdatas) return "";
      const customdatas = JSON.parse(this.chat_opened.customdatas);
      if (!dataName) return customdatas;
      let value = "";
      if (dataName) {
        customdatas.map((cdt) => {
          if (cdt.name == dataName) {
            value = cdt.value;
          }
        });
      }
      return value;
    },
    async loadMoreMedia() {
      const mediasIDB = await this.db.medias
        .where("contactId")
        .equals(this.chat_opened.id)
        .toArray();
      if (mediasIDB.length > 0) {
        this.medias = mediasIDB;
      }
      const chatId = this.chat_opened.id_api;
      let offset = 0;
      if (this.medias) {
        offset = this.medias.length;
      }
      this.isLoading = true;
      api
        .get(`messages/media/whatsapp/${chatId}/${offset}/20`)
        .then((res) => {
          this.db.medias.bulkAdd(res.data);
          this.medias.push(res.data);
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    getThumb(url) {
      if (!url) return "";
      let urlParse = url;
      if (!url.includes(".jpg") && !url.includes(".jpeg")) {
        urlParse = url.replace(/\.[0-9a-z]+$/i, ".png");
      }
      urlParse = urlParse.replace("image/", "image/_thumb/");
      urlParse = urlParse.replace("pdf/", "pdf/_thumb/");
      urlParse = urlParse.replace("video/", "video/_thumb/");
      return "https://titanchat-uploads.s3.sa-east-1.amazonaws.com/" + url;
    },

    showInfoChat() {
      const has = $(".right-sidebar").hasClass("show-right-sidebar");
      if (has) {
        $(".right-sidebar").addClass("hide-right-sidebar");
        $(".right-sidebar").removeClass("show-right-sidebar");
        if ($(window).width() > 991 && $(window).width() < 1201) {
          $(".chat").css("margin-left", 0);
        }
        if ($(window).width() < 992) {
          $(".chat").removeClass("hide-chatbar");
        }
        return true;
      }
      $(".right-sidebar").addClass("show-right-sidebar");
      $(".right-sidebar").removeClass("hide-right-sidebar");
      if ($(window).width() > 991 && $(window).width() < 1201) {
        $(".chat:not(.right-sidebar .chat)").css("margin-left", -chat_bar);
      }
      if ($(window).width() < 992) {
        $(".chat:not(.right-sidebar .chat)").addClass("hide-chatbar");
      }
    },
    getNameParticipantGroup(idApi, user, participant) {
      if (participant.name) {
        return participant.name;
      } else {
        return participant.id.replace("@s.whatsapp.net", "");
      }
      /*
            var name = user;
            this.data.contacts.map((ctt) => {
                if(ctt.sender.id_api == idApi) {
                    name =  ctt.sender.name
                }
            })
            return name
            */
    },
    getPhotoParticipantGroup(idApi, participant) {
      if (participant.profilePicUrl) {
        return this.apiURL + participant.profilePicUrl;
      } else {
        return userNoPhotoUrl;
      }
      /*
            var photo = userNoPhotoUrl;
            this.data.contacts.map((ctt) => {
                if(ctt.sender.id_api == idApi) {
                    photo =  this.apiURL+ctt.sender.profilePicUrl
                }
            })
            return photo
            */
    },
    getIsOnlineParticipantGroup(idApi, participant) {
      if (participant.isOnline) {
        return this.apiURL + participant.isOnline;
      } else {
        return false;
      }
      /*
            var isOnline = false;
            this.data.contacts.map((ctt) => {
                if(ctt.sender.id_api == idApi) {
                    isOnline = ctt.sender.isOnline
                }
            })
            return isOnline
            */
    },
  },
  computed: {
    chatIsGroup() {
      return this.chat_opened.id_api.includes("@g.us");
    },
    createdDate() {
      return moment.unix(this.chat_opened.creation).format("DD/MM/YYYY");
    },
    groupQntParticipants() {
      const participants = JSON.parse(this.chat_opened.participants);
      if (!participants) return 0;
      return participants.length;
    },
    groupParticipants() {
      const pt = JSON.parse(this.chat_opened.participants);
      return pt;
    },
    descGroup() {
      if (!this.chat_opened.description) {
        return "";
      } else {
        // Converte Bold, tracejado e italico para mostrar corretamente
        const html = this.chat_opened.description
          .replace(/\B\*(.*?)\*/gm, "<strong>$1</strong>")
          .replace(/\B\~(.*?)\~/gm, "<strike>$1</strike>")
          .replace(/\b\_(.*?)\_/gm, "<i>$1</i>")
          .replace(/\n/g, "<br/>")
          .replace(
            /(^|&lt;|\s)(www\..+?\..+?)(\s|&gt;|$)/g,
            '$1<a target="_blank"  href="http://$2">$2</a>$3'
          )
          .replace(
            /(^|&lt;|\s)(((https?|ftp):\/\/|mailto:).+?)(\s|&gt;|$)/g,
            '$1<a  target="_blank"  href="$2">$2</a>$5'
          );
        return html;
      }
    },
    ignoreBot: {
      get() {
        return this.chat_opened.ignoreBot;
      },
      async set(value) {
        try {
          await api.post("/contacts/ignore-bot", {
            id: this.chat_opened.id,
            ignoreBot: value,
          });
          this.data.editContact(this.chat_opened.id, {
            ignoreBot: value,
          });
        } catch (error) {
          this.data.editContact(this.chat_opened.id, {
            ignoreBot: !value,
          });
        }
      },
    },
  },
};
</script>
