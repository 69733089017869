<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import userNoPhotoUrl from "../../assets/img/profile-photo.jpg";
import { config } from "../../public/config";
import { useStore } from "../../stores/dataStore";
import { forwardMessages } from "../../helpers/message";
import VueBasicAlert from "vue-basic-alert";
import { api } from "../../service/api";
import Swal from "sweetalert2";

export default {
  setup() {
    const data = useStore();

    return { data };
  },
  props: ["chat_opened"],
  components: {
    PerfectScrollbar,
    VueBasicAlert,
  },
  async mounted() {
    this.contacts = this.data.contacts;
    $("#perfect-forward").css({ height: "280px" });
    // Busca de contatos para encaminhar
    $("#search-contact-forward").on("keyup", function () {
      const value = $(this).val().toLowerCase();
      $("#perfect-forward ul li").filter(function () {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const item = this;
        $(item).toggle(
          $(item).text().toLowerCase().replace("-", "").indexOf(value) > -1
        );
      });
    });
  },
  data() {
    return {
      baseURL: config.$api_url + "/",
      forward_msgid_copy: [],
      checkbox: {},
      searchQuery: "",
      contacts: [],
      isSearching: false,
      isTyping: true,
      timeTyping: null,
    };
  },
  methods: {
    checkForward(chatId) {
      const el = document.getElementById("forward-" + chatId);
      const contactId = el.getAttribute("contactId");
      if (this.data.forward_chatsId.includes(chatId)) {
        this.data.forward_chatsId = this.data.forward_chatsId.filter(
          (chat) => chat != chatId
        );
        this.data.forward_contactIds = this.data.forward_contactIds.filter(
          (chat) => chat != contactId
        );
        el.checked = false;
        this.checkbox[chatId] = false;
      } else {
        this.data.forward_chatsId.push(chatId);
        this.data.forward_contactIds.push(contactId);
        el.checked = true;
        this.checkbox[chatId] = true;
      }
    },
    async handleForwardMessage() {
      this.$refs.alert.showAlert(
        "success", // There are 4 types of alert: success, info, warning, error
        "Encaminhando mensagens...", // Message of the alert
        "Por favor, aguarde...", // Header of the alert
        {
          iconSize: 35, // Size of the icon (px)
          iconType: "solid", // Icon styles: now only 2 styles 'solid' and 'regular'
          position: "bottom right",
        } // Position of the alert 'top right', 'top left', 'bottom left', 'bottom right'
      );
      if (!this.data.forward_chatsId && !this.data.forward_msgIds) return;
      const els = document.querySelectorAll(".check-forward input");
      els.forEach((el) => {
        el.checked = false;
      });
      await forwardMessages(
        "whatsapp",
        this.data.forward_contactIds,
        this.data.forward_chatsId,
        this.data.forward_msgIds,
        this.data.forward_messages,
        {
          openChat: this.data.forward_openChat,
        }
      );
      this.data.modalForward = false;
      this.data.forward_chatsId = [];
      this.data.forward_contactIds = [];
      this.data.forward_msgIds = [];
      this.data.forward_openChat = true;
      this.data.forward_messages = [];
      this.checked = {};
      this.clearSearch();
    },
    async inputQuery() {
      this.isSearching = true;
      clearTimeout(this.timeTyping);
      this.timeTyping = setTimeout(async () => {
        this.findContact();
      }, 500);
    },
    async findContact() {
      if (this.searchQuery == "") {
        this.isSearching = false;
        this.contacts = [];
        return;
      }
      try {
        const { data } = await api.get(`/contacts/find`, {
          params: {
            query: this.searchQuery,
            includeGroup: true,
          },
        });
        this.contacts = data;
        this.data.addContactsOnStore(data);
        this.isSearching = false;
      } catch (error) {
        Swal.fire("Oops!", "Houve um erro ao buscar contatos...");
      }
    },
    getProfilePic(photoUrl) {
      if (!photoUrl) {
        return userNoPhotoUrl;
      } else {
        return photoUrl;
      }
    },
    noProfilePic() {
      return userNoPhotoUrl;
    },

    clearSearch() {
      $("#search-contact-forward").val("");

      const value = "";
      $("#perfect-forward ul li").filter(function () {
        $(this).toggle(
          $(this).text().toLowerCase().replace("-", "").indexOf(value) > -1
        );
      });
    },
  },
  computed: {
    getContactsList() {
      if (this.data.forward_chatType === "whatsapp") {
        return this.contacts.filter((ctt) => ctt.contact_type === "whatsapp");
      } else if (this.data.forward_chatType === "telegram_bot") {
        return this.contacts.filter(
          (ctt) => ctt.contact_type === "telegram_bot"
        );
      } else {
        return this.contacts;
      }
    },
  },
};
</script>

<template>
  <vue-basic-alert :duration="300" :closeIn="4000" ref="alert" />
  <!-- Forward message -->
  <div class="modal fade custom-border-modal" id="forward-message">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <span class="material-icons group-add-btn">arrow_forward</span
            >Encaminhar mensagens ({{ data.forward_msgIds?.length }})
          </h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="material-icons">close</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="search_chat has-search me-0 ms-0">
            <form>
              <span class="fas fa-search form-control-feedback"></span>
              <input
                class="form-control chat_input"
                id="search-contact-forward"
                type="text"
                v-model="searchQuery"
                @input="inputQuery"
                placeholder="Buscar contatos"
              />
              <button type="reset" id="reset" v-on:click="clearSearch()">
                &times;
              </button>
            </form>
          </div>
          <div class="sidebar">
            <div
              class="settings-col"
              style="margin-top: 5px; margin-bottom: 10px"
            >
              <ul>
                <li class="d-flex">
                  <label class="switch">
                    <input
                      type="checkbox"
                      checked="checked"
                      id="openChatinMyPanel"
                      v-model="data.forward_openChat"
                    />
                    <span class="slider round"></span>
                  </label>
                  <div>
                    <span>Abrir chat em meu painel</span>
                  </div>
                </li>
              </ul>
            </div>
            <perfect-scrollbar id="perfect-forward" class="scroll-area">
              <div
                class="message-welcome-center"
                v-if="contacts.length == 0 && !isSearching && searchQuery == ''"
              >
                <p>
                  Digite o nome ou telefone do
                  <span class="text-highlight">contato</span> que deseja buscar!
                </p>
              </div>
              <div class="message-welcome-center" v-if="isSearching">
                <p>
                  Buscando
                  <span class="text-highlight">{{ searchQuery }}...</span>
                </p>
              </div>
              <div
                class="message-welcome-center"
                v-if="
                  !isSearching && searchQuery !== '' && contacts.length == 0
                "
              >
                <p>
                  Nada foi encontrado para
                  <span class="text-highlight">{{ searchQuery }}...</span>
                </p>
              </div>
              <ul
                class="user-list"
                v-for="contact in getContactsList"
                v-bind:key="contact?.id_api"
              >
                <li class="user-list-item">
                  <div class="avatar">
                    <img
                      v-lazy="{
                        src: getProfilePic(contact?.profilePicUrl),
                        error: noProfilePic(),
                      }"
                      class="rounded-circle"
                      alt="image"
                    />
                  </div>
                  <div
                    class="users-list-body align-items-center"
                    v-on:click="checkForward(contact?.id_api)"
                  >
                    <div>
                      <h5>{{ contact?.name }}</h5>
                      <template v-if="contact?.pushname">
                        <span><b>Interno:</b> {{ contact?.pushname }}</span>
                      </template>
                      <span v-if="contact?.id_api.includes('@c.us')">{{
                        contact?.id_api.replace(
                          /([0-9]{2})?([0-9]{2})?([0-9]{4,5})?([0-9]{4})(@c\.us)/,
                          "($2) $3-$4"
                        )
                      }}</span>
                    </div>
                    <div class="last-chat-time">
                      <label class="custom-check check-forward">
                        <input
                          type="checkbox"
                          v-on:change="checkForward(contact?.id_api)"
                          :id="'forward-' + contact?.id_api"
                          :contactId="contact?.id"
                          :value="contact?.id"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </li>
              </ul>
            </perfect-scrollbar>
          </div>
          <div
            class="form-row profile_form text-end float-end m-0 mt-3 align-items-center"
          >
            <!-- Button -->
            <div class="cancel-btn me-3">
              <a
                href="javascript:void(0);"
                data-bs-dismiss="modal"
                v-on:click="clearSearch"
                aria-label="Close"
                >Cancelar</a
              >
            </div>
            <div class="">
              <button
                type="button"
                class="btn btn-block newgroup_create mb-0"
                v-on:click="handleForwardMessage"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Encaminhar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Forward message -->
</template>
