<template>
  <div class="modal fade" id="edit-label">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <span class="material-icons">bookmark</span>Editar etiqueta
          </h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="material-icons">close</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group react-body">
            <div class="buttom-edit-label" v-on:click="editLabel(0)">
              Sem etiqueta
            </div>
            <div
              class="buttom-edit-label"
              v-for="lbl in data.labels"
              v-bind:key="lbl.id"
              v-on:click="editLabel(lbl.id)"
              :style="'background-color:' + lbl.color"
            >
              {{ lbl.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { api } from "../../service/api";
import { useStore } from "../../stores/dataStore";

export default {
  props: ["chat_opened"],
  setup() {
    const data = useStore();

    return { data };
  },
  methods: {
    editLabel(id) {
      $(".close").click();

      api
        .post("/contacts/upgrade/labels", {
          contact_type: "whatsapp",
          idApi: this.chat_opened.id_api,
          labels: id,
        })
        .then((this.chat_opened.labels = id));
    },
  },
};
</script>
