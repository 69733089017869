<template>
  <!-- Add User -->
  <div class="modal fade" id="del-mensagem">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <span class="material-icons">delete</span>Deletar mensagem
          </h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="material-icons">close</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            Tem certeza que deseja deletar essa mensagem?<br />
            <br />
            A mensagem será deletada para você, e para a pessoa com quem você
            está conversando.
          </div>
          <!-- Card -->
          <div
            class="form-row profile_form text-end float-end m-0 align-items-center"
          >
            <!-- Button -->
            <div class="cancel-btn me-4">
              <a
                href="javascript:void(0);"
                data-bs-dismiss="modal"
                aria-label="Close"
                >Cancelar</a
              >
            </div>
            <div class="">
              <button
                type="button"
                class="btn btn-block newgroup_create mb-0"
                v-on:click="deleteMessage"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Deletar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add User -->
</template>
<script lang="ts">
import { PropType, defineComponent } from "vue";
import { socket } from "../../service/socket";
import { Message } from "../../models/message.model";
import { Contact } from "../../types/Contact";
import { useStore } from "../../stores/dataStore";

export default defineComponent({
  setup() {
    return {
      User: () => useStore(),
    };
  },
  props: {
    message: {
      type: Object as PropType<Message | null>,
      required: true,
    },
    chat: {
      type: Object as PropType<Contact>,
    },
  },
  methods: {
    deleteMessage() {
      socket.emit("message:delete", {
        contact: this.chat,
        message: this.message,
      });
      /*
      this.User().editMessage(this.message.contactId, this.message.msgId, {
        ack: "-8",
      });*/
      //this.message.ack = "-8";
      this.$emit("deletarMensagem");
    },
  },
});
</script>
